/* Custom columns */
@mixin column-count($count) {
  -webkit-column-count: $count;
  -moz-column-count: $count;
  column-count: $count;
}

@mixin column-width($width) {
  -webkit-column-width: $width;
  -moz-column-width: $width;
  column-width: $width;
}

@mixin column-fill($fill) {
  -webkit-column-fill: $fill;
  -moz-column-fill: $fill;
  column-fill: $fill;
}

@mixin column-break-inside($break) {
  -webkit-column-break-inside: $break;
  page-break-inside: $break;
  break-inside: $break;
}

@mixin column-gap($gap) {
  -webkit-column-gap: $gap;
  -moz-column-gap: $gap;
  column-gap: $gap;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

@mixin flex-align-justify($align: center, $justify: center) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
}

@mixin background-size($size) {
  -webkit-background-size: $size;
  background-size: $size;
}
