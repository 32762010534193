/* You can add global styles to this file, and also import other style files */
@import 'variables';
@import 'mixins';
@import '../../node_modules/bootstrap/scss/bootstrap';
@import '@ng-select/ng-select/themes/default.theme.css';

html {
  font-size: 14px;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

body {
  color: $black-color;
  line-height: 1;
  overflow: hidden;
}

.grey-bg {
  background: $content-grey-bg;
}

.default-button {
  width: 100%;
  height: 3.4rem;
  margin-top: 3rem;
  color: $primary-text-color;
  font-weight: bold;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
  }

  @include flex-align-justify(center, center);
  @include border-radius(2rem);
}

.default-input {
  height: 3.4rem;
  border-radius: 1.7rem;
  padding-left: 1.7rem;
  padding-right: 1.7rem;
  outline: none !important;
  font-weight: bold;
}

.modal-dialog {
  max-width: 253px;
  margin-top: calc(50vh - 106px);
}

::-webkit-scrollbar {
  width: 0.5em;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 1em;
  background-color: $grey-color;
}

.card-container {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  height: 100%;

  &.mobile {
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
  }

  &.no-cards {
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .no-cards-title {
    color: white;
    font-size: 25px;
  }
}

ngb-tooltip-window {
  .tooltip-inner {
    width: 29vw;
    max-width: 350px;
    background-color: $dark-brown-color;
  }

  .arrow {
    &:before {
      border-top-color: $dark-brown-color !important;
    }
  }
}
